/* Card */
.c-card {
  width: 100%;
  max-width: 360px;
  background: #fff;
  background: var(--theme-content-section-background-color);
  border: 0;
  border-radius: 16px;
  overflow: hidden;
  display: block;
  position: relative;
  transition: all 0.4s ease;
  height: 100%;
}

.c-card:hover {
  box-shadow: 0px 4px 16px 8px rgba(0, 0, 0, 0.1);
}

.c-card__pic {
  width: 100%;
  height: 240px;
  position: relative;
}

.c-card__pic img {
  width: inherit;
  height: inherit;
  /* background: #eee; */
  object-fit: cover;
}

.c-card__pic__tag {
  width: 100%;
  padding: 4px 8px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap-reverse;
}

.c-card__pic__tag object {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: rgba(32, 32, 32, 1);
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 4px;
  margin-right: 4px;
}

.c-card__pic__tag a {
}

.c-card__info {
  padding: 16px;
}

.c-card__info__ttl {
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.35;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.c-card__info__desc {
  height: 42px;
  font-size: 14px;
  line-height: 1.5;
  color: #555555;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 8px;
}

.c-card__info__week {
  height: 18px;
  font-size: 12px;
  line-height: 1.5;
  color: #aaa;
  margin-top: 8px;
}
.c-card__info__week span {
  display: inline-block;
  background: #0094cc;
  background: var(--theme-primary-color);
  color: #fff;
  font-weight: bold;
  padding: 0.125rem 0.3rem;
  border-radius: 12px;
}
.c-card__info__week span.close {
  background: #ccc;
}

.c-card__info__bottom {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.c-card__info__bottom__place {
  width: calc(100% - 140px);
  font-size: 14px;
  font-weight: bold;
  color: #ffcc00;
  color: var(--theme-secondary-color);
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.c-card__info__bottom__plice {
  width: 140px;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
}

.c-card__pic__labelframe {
  position: absolute;
  top: 8px;
  left: 0;
}
.c-card__pic__labelframe > div + div {
  margin-top: 4px;
}
.c-card__pic__labelframe__recommend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-card__pic__labelframe__recommend div {
  width: 32px;
  height: 32px;
  border: 1px solid #fff;
  border-radius: 100%;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.c-card__pic__labelframe__recommend div {
  background: #2cb582;
}
.c-card__pic__labelframe__recommend div img {
  width: 16px;
  height: 16px;
}
.c-card__pic__labelframe__recommend p {
  width: 132px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: #ff0045;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  margin-left: -36px;
  position: relative;
}
.c-card__pic__labelframe__recommend p::before,
.c-card__pic__labelframe__recommend p::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 8px 0 0;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -8px;
}
.c-card__pic__labelframe__recommend p::after {
  transform: scale(1, -1);
}
.c-card__bottom__original__price {
  text-align: right;
  color: #888;
  text-decoration: line-through;
}
.c-card__pic__labelframe__promotion {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #ff0045;
  padding: 0 8px;
  position: relative;
  display: inline-block;
}
.c-card__pic__labelframe__promotion::before,
.c-card__pic__labelframe__promotion::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 8px 0 0;
  border-color: #ff0045 transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -8px;
}
.c-card__pic__labelframe__promotion::after {
  transform: scale(1, -1);
}
